.faqs {
  width: 100%;
  /* padding: 75px 0; */
  background-image: linear-gradient(rgb(243, 243, 243), rgb(243, 243, 243));
}

.faqs__content {
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.faqs__left {
  width: 50%;
}

.faqs__right {
  width: 50%;
}

@media only screen and (max-width: 1024px) {
  .faqs {
    /* padding: 35px 0; */
  }

  .faqs__content {
    flex-direction: column;
  }

  .faqs__left,
  .faqs__right {
    width: 100%;
  }
}
