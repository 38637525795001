.accordionWrapper {
  /* padding: 75px 0; */
}

.accordionButton {
  background: white;
  border-left: 4px solid #171717;
  border-left: 4px solid #cfcfcf;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.accordionButton:hover {
  border-color: #171717;
}
